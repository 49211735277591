import actions from './actions';

const clearAlert = (params) => (dispatch) => {
    console.info('[operation] clearAlert()', params);
    dispatch(actions.clearAlert.request(params));
    return dispatch(actions.clearAlert.success(params));
};

export {
    clearAlert,
};
