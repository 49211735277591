import { createSelector } from 'reselect';


const getOwnerTokens = (state) => {
	let tokens = state.tokens.owned.slice();
	tokens.sort((a, b) => b - a);
	return tokens;
};

const getTokenOwner = (state, params) => {
	const { tokenId } = params;
	if (state.tokens.owners[tokenId]) {
		return state.tokens.owners[tokenId];
	}
	return null;
}

const getTotalSupply = (state) => {
	return state.tokens.totalSupply;
};

const getMintResult = (state) => {
	return state.tokens.mintResult;
}

const getMintState = (state) => {
	return state.tokens.mintState;
}

export {
	getOwnerTokens,
	getTokenOwner,
	getTotalSupply,
	getMintResult,
	getMintState,
}
