// Reducer
const reducer = (
	state = {},
	action,
) => {
	const { type, meta } = action;

	// Match type actions
	const matches = /(.*)_(REQUEST|PENDING|CANCELLED|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return state;

	// Get selection groups
	const [, requestName/* , requestState */] = matches;

	// Set requestName to message (true) or false in state
	return {
		...state,
		[requestName]: meta ? meta.message : false,
	};
};

// Selector
export const getApiStatus = (state) => state.apiStatus;

export default reducer;
