import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {connect} from 'react-redux';

import appConfig from '@@Config';
import NFT from '@@Components/NFT';
import {selectors as blockchainSelectors} from '@@Redux/blockchain';
import {operations as tokenOperations, selectors as tokenSelectors} from '@@Redux/tokens';


const Token = ({
	account,
	blockchain,
	getTokenOwner,
	tokenOwner,
	baseURI,
}) => {

	let { id } = useParams();
	const { contract } = blockchain;

	useEffect(() => {
		if (contract && id) {
			getTokenOwner({ tokenId: id });
		}
	}, [id, contract, getTokenOwner]);

	const isOwner = account && account.address === tokenOwner;
	const owner = isOwner ? 'You' : tokenOwner

	if (contract) {
		return (
			<Fragment>
				<NFT tokenId={id} URI={`${baseURI}${id}`}/>
				<div className="my-2 text-center">
					Owner: {owner}
				</div>
			</Fragment>
		);
	}
	return null;
};



const mapState = (state, ownProps) => {
	const blockchain = blockchainSelectors.getBlockchain(state);
	const { account, baseURI } = blockchain;
	const tokenOwner = tokenSelectors.getTokenOwner(state, { tokenId: ownProps.match.params.id });

	return {
		account,
		blockchain,
		tokenOwner,
		baseURI,
	};
};

const mapDispatch = {
	getTokenOwner: tokenOperations.getTokenOwner,
};

export default connect(
	mapState,
	mapDispatch,
)(Token);
