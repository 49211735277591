import React, { useEffect } from 'react';
import {connect} from 'react-redux';

import config from '@@Config';
import { operations as tokenOperations, selectors as tokenSelectors } from '@@Redux/tokens';

import NFT from './NFT';
import { selectors as blockchainSelectors } from '@@Redux/blockchain';


const OwnerTokens = ({
	getOwnerTokens,
	tokens,
	baseURI,
	account,
}) => {

	useEffect(() => {
		getOwnerTokens();
	}, [getOwnerTokens]);


	if (tokens.length > 0) {
		return (
			<div className="my-5 pt-5">
				<h2 className="heading-4 mb-4 text-center">Minted</h2>

				<div className="row px-5 justify-content-center">
					{tokens.map(x => {
						return (
							<div key={x} className="col-sm-6 col-md-4 col-lg-3">
								<NFT tokenId={x} URI={`${baseURI}${x}`} preview={true} />
							</div>
						);
					})}
				</div>

			</div>
		);
	}
	return null;
};

const mapState = (state) => {
	const blockchain = blockchainSelectors.getBlockchain(state);
	const { baseURI, account } = blockchain;
	const tokens = tokenSelectors.getOwnerTokens(state);
	return {
		tokens,
		baseURI,
		account,
	};
};

const mapDispatch = {
	getOwnerTokens: tokenOperations.getOwnerTokens,
};

export default connect(
	mapState,
	mapDispatch,
)(OwnerTokens);
