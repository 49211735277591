import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import { Helmet } from 'react-helmet';
import stringify from 'json-stringify-safe';
import Mint from '@@Components/Mint';
import WalletConnection from '@@Components/WalletConnection';
import { colors } from '@@Constants';
import OwnerTokens from '@@Components/OwnerTokens';
import { operations as blockchainOperations, selectors as blockchainSelectors } from '@@Redux/blockchain';
import { operations as tokenOperations, selectors as tokenSelectors }  from '@@Redux/tokens';

import config from '@@Config';


const Home = ({
	contract,
	signer,
	account,
	getTotalSupply,
	totalSupply,
}) => {

	useEffect(() => {
		if (contract) {
			getTotalSupply();
		}
	}, [getTotalSupply, contract]);

	return (
		<div className="container position-relative">

			<Helmet title="a 3333-piece generative art NFT collection" />

			<div className="container mb-2">
				<div className="row justify-content-center">
					<div className="col-10 col-xl-8 text-center">
						<p className="subheading mb-3">Mint Price: <em>FREE</em></p>
						<p><small><em>All ASTRAL holders given priority<br/>for additional upcoming mints.</em></small></p>
					</div>
				</div>
			</div>

			<div className="row justify-content-center mb-4">
				<div className="col-auto text-center">

					<WalletConnection/>

					{(contract && signer && account) && (
						<div>
							<Mint contract={contract} signer={signer}/>
							Supply: {totalSupply} / {config.token.count}
						</div>
					)}

				</div>
			</div>

			{contract && signer && (
				<OwnerTokens contract={contract} signer={signer} />
			)}
		</div>
	);

};

const mapState = (state) => {

	const blockchain = blockchainSelectors.getBlockchain(state);
	const { contract, signer, account } = blockchain;

	const totalSupply = tokenSelectors.getTotalSupply(state);

	return {
		contract,
		signer,
		account,
		totalSupply,
	};
};

const mapDispatch = {
	getTotalSupply: tokenOperations.getTotalSupply,
};

export default connect(
	mapState,
	mapDispatch,
)(Home);


