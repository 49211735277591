const { localStorage } = global;
const name = 'Astral Alignment';
const shortName = 'Astral';
const description = 'Astral Alignment: 3333 NFT generative art collection created by debloom';
const basePageTitle = 'Astral Alignment';

const ABI = require('../artifacts/contracts/AstralAlignment.sol/AstralAlignment.json');

module.exports = {
	basePageTitle,
	name,
	shortName,
	description,
	creatorName: 'debloom',
	env: process.env.NODE_ENV,
	api: {
		hostname: process.env.API_BASE_URL,
		port: process.env.PORT,
		baseUrl: process.env.API_BASE_URL,
	},
	contract: {
		address: {
			'1337': process.env.LOCAL_CONTRACT_ADDRESS,
			'1': '0x918114076beD1D0F3c66Dcb20D6FDD3c4a92B750',
			'5': '0xDd7702fEbe1ab59BdeeADf4694F7c0f66A33F645',
		},
		abi: ABI.abi,
	},
	token: {
		count: 3333,
		mintPrice: 0.00,
		mintMax: 3,
		gasEstimates: {
			mint: 180000,
			recycle: 180000,
		},
		currency: {
			symbol: 'ETH',
		},
	},
	recaptcha: {
		siteKey: process.env.RECAPTCHA_SITE_KEY,
	},
	links: {
		twitter: 'https://www.twitter.com/debloom_art',
		discord: '',
		openSea: 'https://opensea.io/collection/astral-alignment',
		etherscan: 'https://etherscan.io/address/0x918114076beD1D0F3c66Dcb20D6FDD3c4a92B750',
	}
};
