import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '@@Constants';


const NFT = ({
	baseURI,
	tokenId,
	URI,
	preview,
}) => {

	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		async function getMetadata() {
			const response = await fetch(URI, {
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
			});
			if (response.status === 200) {
				const json = await response.json();
				setMetadata(json);
			}
		}
		getMetadata();
	}, [tokenId]);

	let backgroundColor, foregroundColor, markerColor, markerName;
	if (metadata) {
		if (metadata.attributes) {
			markerName = metadata.attributes.find((attr) => attr['trait_type'] === 'Revealed').value;
			markerColor = metadata['marker_color'];
			foregroundColor = metadata['foreground_color'];
			backgroundColor = metadata['background_color'];
		}

	}

	// const Marker = `${markerName}`;

	return (
		<Fragment>
			{metadata && (
				<div className="my-4">
					<div className="text-center mb-4">
						{preview && (
							<Link to={`/tokens/${tokenId}`}>
								<img src={metadata.image} style={{ width: '10em', height: '10em', border: 'solid 0.5em #fff' }} />
							</Link>
						)}
						{!preview && (
							<img src={metadata.image} style={{ width: '10em', height: '10em', border: 'solid 0.5em #fff' }} />
						)}
					</div>
					<div className="">
						<div className="text-center">
							{preview && (
								<Link to={`/tokens/${tokenId}`} className="heading-5">
									{metadata.name}
								</Link>
							)}
							{!preview && (
								<span className="heading-5">{metadata.name}</span>
							)}
						</div>

						<p className="text-sm text-center">{metadata.description}</p>

						{/*
						<hr />
						<dl className="mb-0">
							{metadata.attributes.map((attribute) => {
								return (
									<Fragment key={attribute.trait_type}>
										<dt>{attribute.trait_type}</dt>
										<dd>{attribute.value.toString()}</dd>
									</Fragment>
								)
							})}
						</dl>
						*/}

						{/*<p>{JSON.stringify(metadata)}</p>*/}
						{/*<p>{JSON.stringify(metadata.attributes.find((attr) => attr['trait_type'] == 'Marker'))}</p>*/}
						{/*<p>{JSON.stringify(metadata.attributes.find((attr) => attr['trait_type'] == 'Marker Color'))}</p>*/}

						{/*<p>{markerName} {markerColor}</p>*/}


					</div>
				</div>
			)}
		</Fragment>
	);
}

export default NFT
