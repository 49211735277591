import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { getApiStatus } from '@@Redux/apiStatus';


const propTypes = {
	message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const Loading = ({
	message = null,
}) => {
	Modal.setAppElement('body');

	if (message) {
		return (
			<Fragment>
				<Modal
					isOpen={true}
					className="modal loading"
					overlayClassName="modal-backdrop"
					contentLabel={message}
				>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body p-5">

								<div className="lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
								{/*<button onClick={closeModal}>close</button>*/}
								<div>
									{message}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</Fragment>
		);
	}
	return null;
};

Loading.propTypes = propTypes;

const mapStateToProps = (state) => {
	const apiStatus = getApiStatus(state);
	const message = Object.values(apiStatus).find(value => value);

	return {
		message,
	};
};

export default connect(
	mapStateToProps,
)(Loading);
