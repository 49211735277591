import types from './types';


const getOwnerTokens = {
	request: () => ({
		type: types.OWNER_TOKENS.REQUEST,
		meta: {
			message: 'Retrieving Tokens',
		}
	}),
	success: (data) => ({
		type: types.OWNER_TOKENS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.OWNER_TOKENS.FAILURE,
		error: true,
		payload: err,
	}),
};

const getTokenOwner = {
	request: (params) => ({
		type: types.TOKEN_OWNER.REQUEST,
		payload: params,
		meta: {
			message: 'Getting Token Owner',
		}
	}),
	success: (data) => ({
		type: types.TOKEN_OWNER.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOKEN_OWNER.FAILURE,
		error: true,
		payload: err,
	}),
};

const mintToken = {
	request: (params) => ({
		type: types.TOKEN_MINT.REQUEST,
		payload: params,
		meta: {
			message: 'Ready to Mint: Please Confirm Wallet Transaction',
		}
	}),
	success: (data) => ({
		type: types.TOKEN_MINT.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOKEN_MINT.FAILURE,
		error: true,
		payload: err,
	}),
};

const tokenMinted = {
	request: (params) => ({
		type: types.TOKEN_MINTED.REQUEST,
		payload: params,
		meta: {
			message: `Minting Token #${params.tokenId}: Please Wait.`,
		}
	}),
	success: (data) => ({
		type: types.TOKEN_MINTED.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOKEN_MINTED.FAILURE,
		error: true,
		payload: err,
	}),
};

const getTotalSupply = {
	request: () => ({
		type: types.TOTAL_SUPPLY.REQUEST,
		meta: {
			// message: 'Retrieving Total Supply',
		}
	}),
	success: (data) => ({
		type: types.TOTAL_SUPPLY.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TOTAL_SUPPLY.FAILURE,
		error: true,
		payload: err,
	}),
};

const getMintState = {
	request: () => ({
		type: types.MINT_STATE.REQUEST,
		meta: {}
	}),
	success: (data) => ({
		type: types.MINT_STATE.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.MINT_STATE.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	getOwnerTokens,
	getTokenOwner,
	mintToken,
	tokenMinted,
	getTotalSupply,
	getMintState,
}
