import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {colors} from '@@Constants';
import config from '@@Config';

const { twitter } = config.links;

const Header = () => {

	const colorValues = [colors.BLACK].map((c) => c.hex);
	const [bgColor, setBgColor] = useState(colorValues[0]);
	const [fgColor, setFgColor] = useState(0);

	useEffect(() => {
		// const timer = setInterval(() => {
		// 	const rand = Math.floor(Math.random()*colorValues.length);
		// 	setBgColor(colorValues[rand]);
		// 	setFgColor(bw => bw === 0 ? 1 : 0);
		// }, 1000);
		// return () => clearInterval(timer);
	}, []);

	return (
		<header className="header" id="header" /*style={{ backgroundColor: bgColor.toString() }}*/ style={{ paddingTop: '10vh', justifyContent: "center", alignItems: 'center', display: 'flex' }}>
			<div className="container-fluid">
				<div className="row justify-content-center">
					<div className="col text-center py-2">
						{/*<NavLink to="/" className="logo" activeClassName="selected">
							<Logo fill='#F4F5F0' />
						</NavLink>*/}

						<NavLink to="/" className="logo mb-2" activeClassName="selected">
							<div className="logo-symbol mb-4">◍</div>
							<h1 className="heading-1 logo-heading">
								Astral<br/><sub className="">Alignment</sub>
							</h1>
						</NavLink>


						<p className="subheading mb-3">3333 NFT generative art collection</p>
						<p className="mb-5"><small>created by <a href={twitter} target="_blank" rel="noopener nofollow noreferrer">debloom</a></small></p>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
