import { eventChannel, END } from 'redux-saga';
import { all, take, takeEvery, takeLatest, put, fork, select, call, actionChannel, cancel } from 'redux-saga/effects';
import { ethers, Wallet } from 'ethers';


import config from '@@Config';
import ERC721 from '@openzeppelin/contracts/build/contracts/ERC721.json';

import { getBlockchain } from '@@Redux/blockchain/selectors';

import actions from './actions';
import types from './types';
import { getFunctions, httpsCallable } from 'firebase/functions';


/**
 Workers
 */
function* getOwnerTokens(action) {
	const params = action.payload;

	function addressEqual(a, b) {
		return a.toLowerCase() === b.toLowerCase();
	}

	try {

		const tokens = new Set();
		const blockchain = yield select(getBlockchain);
		const { contract: token, account } = blockchain;
		const { address } = account;
		//
		const sentLogs = yield token.queryFilter(
			token.filters.Transfer(address, null),
		);

		// console.log('Sent:', sentLogs);

		const receivedLogs = yield token.queryFilter(
			token.filters.Transfer(null, address),
		);

		// console.log('Received:', receivedLogs);

		const logs = sentLogs.concat(receivedLogs)
			.sort((a, b) => {
				return a.blockNumber - b.blockNumber || a.transactionIndex - b.transactionIndex;
			});

		// console.log('Logs:', logs);


		for (const { args: { from, to, tokenId } } of logs) {
			if (addressEqual(to, address)) {
				tokens.add(tokenId.toString());
			} else if (addressEqual(from, address)) {
				tokens.delete(tokenId.toString());
			}
		}

		// console.log('Tokens:', tokens);

		yield put(actions.getOwnerTokens.success({ tokens: [...tokens] }));
	}
	catch (err) {
		console.error(err);
		yield put(actions.getTokens.failure(err));
	}
}

function* getTokenOwner(action) {
	const params = action.payload;
	const { tokenId } = params;

	try {
		const blockchain = yield select(getBlockchain);
		const { contract, address } = blockchain;

		const owner = yield contract.ownerOf(tokenId);
		yield put(actions.getTokenOwner.success({ tokenId, owner }));

	}
	catch (err) {
		console.error(err);
		//err.reason = ERC721: invalid token ID

		yield put(actions.getTokenOwner.failure(err));
	}
}

function* mintToken(action) {
	const params = action.payload;
	const { quantity } = params;

	try {

		const blockchain = yield select(getBlockchain);
		const { contract, address } = blockchain;

		const totalAmount = ethers.utils.parseEther((config.token.mintPrice * quantity).toString());

		const options = {
			value: totalAmount,
			gasLimit: config.token.gasEstimates.mint * quantity,
		};

		const transactionResponse = yield contract.mint(quantity, options);
		console.log('transactionResponse', transactionResponse);

		yield put(actions.mintToken.success({
			transaction: transactionResponse,
		}));

		const transactionReceipt = yield transactionResponse.wait();
		console.log('transactionReceipt', transactionReceipt);

		yield put(actions.tokenMinted.success({
			transaction: transactionResponse,
			receipt: transactionReceipt,
		}));

		yield put(actions.getOwnerTokens.request());

		console.log('transactionReceipt', transactionReceipt);

		const { events = [] } = transactionReceipt;

		// TODO: update to handle quantities (find > filter)
		// const mintedEvent = events.find((e) => e.eventSignature.startsWith('Minted') && e.args.cardId);
		/*const mintedEvents = events.filter((e) => e.eventSignature.startsWith('Minted') && e.args.cardId);

		for (const mintedEvent of mintedEvents) {
			console.log('events', events, mintedEvent);

			const mintedTokenId = Number(mintedEvent.args.cardId);
			console.log('mintedTokenId', mintedTokenId);

			try {
				yield put(actions.tokenMinted.request({ cardId: mintedTokenId }));
				// yield fork(watchTokenMinted);
				const functions = getFunctions();
				const generate = httpsCallable(functions, 'generate', {});

				const payload = {
					tokenId: Number(mintedTokenId),
				};

				console.log('payload', payload)

				yield call(generate, payload);
				yield put(actions.tokenMinted.success(payload));

				yield put(actions.getTotalSupply.request());
			} catch (err) {
				console.error('minted error:', err);
				yield put(actions.tokenMinted.failure(err));
			}
		}*/

	}
	catch (err) {
		console.error(err);
		yield put(actions.mintToken.failure(err));
	}
}

function* getTotalSupply() {
	try {
		const blockchain = yield select(getBlockchain);
		const { contract } = blockchain;
		const totalSupply = yield contract.totalSupply();
		yield put(actions.getTotalSupply.success({ totalSupply }));
	} catch (err) {
		yield put(actions.getTotalSupply.failure(err));
	}
}

function* getMintState() {
	try {
		const blockchain = yield select(getBlockchain);
		const { contract } = blockchain;
		const mintState = yield contract.mintState();
		yield put(actions.getMintState.success({ mintState }));
	} catch (err) {
		yield put(actions.getMintState.failure(err));
	}
}

function* createMintedChannel() {
	const blockchain = yield select(getBlockchain);
	const { contract, account } = blockchain;

	return eventChannel((emit) => {
		const mintHandler = (owner, tokenId) => {
			if (owner === account.address) {
				const data = { owner, tokenId };
				emit(data);
			}
		};

		contract.on('Minted', mintHandler);

		return () => {
			contract.off('Minted', mintHandler);
		};
	});
}

/**
 * Watchers
 */
function* watchOwnerTokens() {
	try {
		yield takeLatest(types.OWNER_TOKENS.REQUEST, getOwnerTokens);
	} catch(err) {
		yield put(actions.getOwnerTokens.failure(err));
	}
}

function* watchTokenOwner() {
	try {
		yield takeLatest(types.TOKEN_OWNER.REQUEST, getTokenOwner);
	} catch(err) {
		yield put(actions.getTokenOwner.failure(err));
	}
}

function* watchTokenMint() {
	try {
		yield takeEvery(types.TOKEN_MINT.REQUEST, mintToken);
	} catch(err) {
		yield put(actions.mintToken.failure(err));
	}
}


export function* watchTokenMinted() {
	const functions = getFunctions();
	const generate = httpsCallable(functions, 'generate', {
		// TODO: use current game + 1 or 0 ??
		gameNumber: 5
	});

	const channel = yield call(createMintedChannel);

	while (true) {

		try {
			yield put(actions.tokenMinted.request());
			const mintPayload = yield take(channel);

			const { tokenId } = mintPayload;

			console.log('watchTokenMinted', tokenId);

			const payload = {
				tokenId: Number(tokenId),
			};

			console.log('payload', payload)

			// TODO: function should accept tokenId and retrieve associated seed from contract
			const mint = yield call(generate, payload);
			yield put(actions.tokenMinted.success(payload));

			yield put(actions.getTotalSupply.request());

			break;
		}
		catch (err) {
			console.error('minted error:', err);
			yield put(actions.tokenMinted.failure(err));
		}
	}
}

function* watchTotalSupply() {
	try {
		yield takeLatest(types.TOTAL_SUPPLY.REQUEST, getTotalSupply);
	} catch(err) {
		yield put(actions.getTotalSupply.failure(err));
	}
}

function* watchMintState() {
	try {
		yield takeLatest(types.MINT_STATE.REQUEST, getMintState);
	} catch(err) {
		yield put(actions.getMintState.failure(err));
	}
}


/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchOwnerTokens),
		fork(watchTokenOwner),
		fork(watchTokenMint),
		fork(watchTotalSupply),
		fork(watchMintState),
	]);
}

export default sagas;
