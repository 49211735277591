import actions from './actions';


const getOwnerTokens = (params) => (dispatch) => {
	console.info('[operation] getOwnerTokens()', params);
	return dispatch(actions.getOwnerTokens.request(params));
};

const getTokenOwner = (params) => (dispatch) => {
	console.info('[operation] getTokenOwner()', params);
	return dispatch(actions.getTokenOwner.request(params));
};

const mintToken = (params) => (dispatch) => {
	console.info('[operation] mintToken()', params);
	return dispatch(actions.mintToken.request(params));
};

const getTotalSupply = () => (dispatch) => {
	console.info('[operation] getTotalSupply()');
	return dispatch(actions.getTotalSupply.request());
};

const getMintState = () => (dispatch) => {
	console.info('[operation] getMintState()');
	return dispatch(actions.getMintState.request());
};

export {
	getOwnerTokens,
	getTokenOwner,
	mintToken,
	getTotalSupply,
	getMintState,
};
