import { combineReducers } from 'redux';
import produce from 'immer';

import types from './types';

const owned = (state = [], action) => produce(state, (draft) => {
	switch (action.type) {
		case types.OWNER_TOKENS.REQUEST:
			return;
		case types.OWNER_TOKENS.SUCCESS:
			const { tokens } = action.payload;
			return tokens.map((token) => Number(token));
		default:
			return state;
	}
});

const owners = (state = {}, action) => produce(state, (draft) => {
	switch (action.type) {
		case types.TOKEN_OWNER.REQUEST:
			return;
		case types.TOKEN_OWNER.SUCCESS:
			const { tokenId, owner } = action.payload;
			draft[tokenId] = owner;
			return;
		default:
			return state;
	}
});

const totalSupply = (state = 0, action) => produce(state, (draft) => {
	switch (action.type) {
		case types.TOTAL_SUPPLY.SUCCESS: {
			const { totalSupply } = action.payload;
			return Number(totalSupply);
		}
		default: return state;
	}
});

const mintState = (state = null, action) => produce(state, (draft) => {
	switch (action.type) {
		case types.MINT_STATE.SUCCESS: {
			const { mintState } = action.payload;
			return mintState;
		}
		default: return state;
	}
});

const transactions = (state = {}, action) => produce(state, (draft) => {
	switch (action.type) {
		case types.TOKEN_MINT.SUCCESS: {
			const { transaction } = action.payload;
			if (transaction && transaction.hash) {
				draft[transaction.hash] = transaction;
			}
			return;
		}
		default: return state;
	}
});



const reducer = combineReducers({
	owned,
	owners,
	totalSupply,
	mintState,
	transactions,
});

export default reducer;
