import types from './types';


const getProvider = {
	request: (params) => ({
		type: types.GET_PROVIDER.REQUEST,
		payload: params,
		meta: {
			// message: 'Getting Provider',
		}
	}),
	success: (data) => ({
		type: types.GET_PROVIDER.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.GET_PROVIDER.FAILURE,
		error: true,
		payload: err,
	}),
};

const connect = {
	request: (params) => ({
		type: types.CONNECT.REQUEST,
		payload: params,
		meta: {
			// message: 'Connecting',
		}
	}),
	success: (data) => ({
		type: types.CONNECT.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.CONNECT.FAILURE,
		error: true,
		payload: err,
	}),
};


const accountUpdate = {
	request: () => ({
		type: types.ACCOUNT_UPDATE.REQUEST,
		meta: {
			message: 'Account Updating',
		}
	}),
	success: (data) => ({
		type: types.ACCOUNT_UPDATE.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.ACCOUNT_UPDATE.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	getProvider,
	connect,
	accountUpdate,
}
