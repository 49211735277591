import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { operations as alertOperations, selectors as alertSelectors } from '@@Redux/alerts';


const propTypes = {
    //message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const Alerts = ({
    alerts,
    clearAlert
 }) => {

    const handleAlertDismiss = (id) => {
        clearAlert({ id });
    }

    if (alerts) {
        return (
            <div className="row justify-content-center">
                <div className="col-10">
                    {alerts.map((alert) => {
                        return (
                            <div key={alert.id} className={`alert alert-${alert.style} alert-dismissible`} role="alert">
                                <h2 className="heading-5 mb-2">
                                    {alert.heading}
                                    &nbsp;
                                    {alert.code ? `(${alert.code})` : ''}
                                </h2>
                                <span>{alert.message}</span>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => handleAlertDismiss(alert.id)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

Alerts.propTypes = propTypes;

const mapState = (state) => {
    const alerts = alertSelectors.getAlerts(state);
    return {
        alerts,
    };
};

const mapDispatch = {
    clearAlert: alertOperations.clearAlert,
};

export default connect(
    mapState,
    mapDispatch,
)(Alerts);
