
const ALERT_CLEAR = {
    name: 'alerts/ALERT_CLEAR',
    REQUEST: 'alerts/ALERT_CLEAR_REQUEST',
    SUCCESS: 'alerts/ALERT_CLEAR_SUCCESS',
    FAILURE: 'alerts/ALERT_CLEAR_FAILURE',
}

export default {
    ALERT_CLEAR,
};
