// https://coolors.co/568484-d8461e-d27900-c1272d-763472-00597b-555555-587e64-db7575-a0aa55
module.exports = {
	WHITE: { name: 'White', hex: '#F4F5F0', backgroundWeight: 5, foregroundWeight: 20, markerWeight: 5 }, // Bright White
	BLACK: { name: 'Black', hex: '#131B23', backgroundWeight: 20, foregroundWeight: 15, markerWeight: 0.25 }, // Black 6
	SUN_DRIED_TOMATO: { name: 'Sun-Dried Tomato', hex: '#752329', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 2 },
	RUBINE_RED: { name: 'Rubine Red', hex: '#C00B5D', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 15 },
	LOLLIPOP: { name: 'Lollipop', hex: '#CC1C3B', backgroundWeight: 5, foregroundWeight: 5, markerWeight: 10 },
	FIERY_CORAL: { name: 'Fiery Coral', hex: '#FF4F58', backgroundWeight: 5, foregroundWeight: 5, markerWeight: 10 },
	BUBBLEGUM: { name: 'Bubblegum', hex: '#E4687B', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 10 }, // Classic Pink
	WARM_RED: { name: 'Warm Red', hex: '#EB5344', backgroundWeight: 5, foregroundWeight: 5, markerWeight: 5 },
	ORANGE_PEEL: { name: 'Orange Peel', hex: '#FA7A35', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 10 },
	GOLDENROD: { name: 'Goldenrod', hex: '#F9AC2F', backgroundWeight: 3, foregroundWeight: 3, markerWeight: 5 },
	GOLD: { name: 'Gold', hex: '#C8B273', backgroundWeight: 0.5, foregroundWeight: 0.5, markerWeight: 1 }, // Rich Gold
	APPLE_GREEN: { name: 'Apple Green', hex: '#B5B644', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 5 },
	DUFFEL_BAG: { name: 'Duffel Bag', hex: '#394034', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 2 },
	SMOKE_PINE: { name: 'Smoke Pine', hex: '#3E6257', backgroundWeight: 0.5, foregroundWeight: 0.5, markerWeight: 1 },
	JOLLY_GREEN: { name: 'Jolly Green', hex: '#007844', backgroundWeight: 3, foregroundWeight: 3, markerWeight: 4 },
	GUMDROP_GREEN: { name: 'Gumdrop Green', hex: '#2EA785', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 6 },
	BLUEBIRD: { name: 'Bluebird', hex: '#009DAE', backgroundWeight: 4, foregroundWeight: 4, markerWeight: 6 },
	MALIBU_BLUE: { name: 'Malibu Blue', hex: '#008CC1', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 5 },
	DUSTY_TURQUOISE: { name: 'Dusty Turquoise', hex: '#649B9E', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 3 },
	POSEIDON: { name: 'Poseidon', hex: '#123955', backgroundWeight: 5, foregroundWeight: 5, markerWeight: 2 },
	IMPERIAL_BLUE: { name: 'Imperial Blue', hex: '#005A92', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 2 },
	REFLEX_BLUE: { name: 'Reflex Blue', hex: '#002490', backgroundWeight: 1, foregroundWeight: 1, markerWeight: 2 },
	GRAPE: { name: 'Grape', hex: '#433455', backgroundWeight: 2, foregroundWeight: 2, markerWeight: 1 },
	SILVER: { name: 'Silver', hex: '#A2A2A1', backgroundWeight: 4, foregroundWeight: 4, markerWeight: 1 },
}

