import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

const middleware = [
	thunk,
	sagaMiddleware,
]

export default middleware;
