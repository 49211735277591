import produce from 'immer';

import types from './types';


const initialState = {
	chainId: undefined,
	provider: undefined,
	signer: undefined,
	contract: undefined,
	account: undefined,
};

const blockchain = (state = initialState, action) => produce(state, draft => {
	let {
		chainId,
		provider,
		signer,
		contract,
		baseURI,
		account,
	} = action.payload || {};

	switch (action.type) {
		case types.CONNECT.REQUEST:
			return;
		case types.CONNECT.SUCCESS:
			draft.chainId = Number(chainId);
			draft.signer = signer;
			draft.contract = contract;
			draft.account = account;
			draft.baseURI = baseURI;

			return;
		case types.GET_PROVIDER.SUCCESS:
			draft.chainId = chainId;
			draft.provider = provider;
			draft.signer = signer;
			return;
		case types.GET_PROVIDER.FAILURE:
			draft.provider = null;
			draft.signer = null;
			return;
		case types.ACCOUNT_UPDATE.SUCCESS:
			draft.signer = signer;
			draft.account = account;
			return;
		case types.ACCOUNT_UPDATE.FAILURE:
			draft.signer = null;
			draft.account = null;
			return;
		default:
			return state;
	}
});

export default blockchain;
