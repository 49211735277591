import { channel } from 'redux-saga';
import { all, take, takeLatest, put, fork, call, select }  from 'redux-saga/effects';
import { ethers, Wallet } from 'ethers';

import config from '@@Config';
import { getBlockchain } from './selectors';
import actions from './actions';
import types from './types';

const { ethereum, location } = window;

const accountUpdateChannel = channel();

/**
 Workers
 */
function* getProvider(action) {
	const params = action.payload;
	let provider;

	if (typeof ethereum !== 'undefined') {
		provider = new ethers.providers.Web3Provider(ethereum, 'any');

		const accounts = yield provider.listAccounts();
		const chainId = yield ethereum.request({ method: 'net_version' });
		const signer = yield provider.getSigner();

		yield put(actions.getProvider.success({
			provider,
			chainId,
			signer,
		}));

		if (accounts.length > 0) {
			yield connect();
		}

	} else {
		yield put(actions.getProvider.failure('Please install Metamask'));
	}
}


function* connect(action) {
	try {
		const blockchain = yield select(getBlockchain);
		const { provider } = blockchain;

		const chainId = yield ethereum.request({ method: 'net_version' });
		const signer = yield provider.getSigner();
		const address = yield signer.getAddress();
		const balance = yield provider.getBalance(address);

		switch (chainId) {
			case '1337': {
				console.log('[blockchain] localhost');
				break;
			}
			case '1': {
				console.log('[blockchain] Ethereum Mainnet');
				break;
			}
			case '5': {
				console.log('[blockchain] Ethereum Testnet (Goerli)');
				break;
			}
			default: {
				console.log('[blockchain] Unknown Chain:', chainId);
			}
		}

		// add listeners
		ethereum.on('chainChanged', (chain) => {
			location.reload();
		});

		ethereum.on('accountsChanged', async (accounts) => {
			location.reload();
		});

		if (chainId === '1337' || chainId === '1' || chainId === '5') {
			let contractAddress = config.contract.address[chainId];
			const contract = new ethers.Contract(contractAddress, config.contract.abi, signer);

			try {
				if (contract) {
					const baseURI = yield contract.baseURI();
					const result = {
						provider,
						signer,
						contract,
						baseURI,
						chainId,
						account: {
							address,
							balance,
						},
					};

					yield put(actions.connect.success(result));
				} else {
					yield put(actions.connect.failure('Contact Not Found'));
				}
			} catch (err) {
				yield put(actions.connect.failure(err.message));
			}
		} else {
			yield put(actions.connect.failure('Incorrect Network'));
		}
	}
	catch (err) {
		console.error(err);
		yield put(actions.connect.failure(err));
	}
}

function* accountUpdate(action) {
	const account = action.payload;

	yield put(actions.accountUpdate.success({ address: account }));
}

/**
 * Watchers
 */
function *watchGetProvider() {
	try {
		yield takeLatest(types.GET_PROVIDER.REQUEST, getProvider);
	} catch(err) {
		yield put(actions.getProvider.failure(err));
	}
}

function *watchConnect() {
	try {
		yield takeLatest(types.CONNECT.REQUEST, connect);
	} catch(err) {
		yield put(actions.accountUpdate.failure(err));
	}
}

function *watchAccountUpdate() {
	try {
		yield takeLatest(types.ACCOUNT_UPDATE.REQUEST, accountUpdate);
	} catch(err) {
		yield put(actions.connect.failure(err));
	}
}

function *watchAccountUpdateChannel() {
	while (true) {
		const action = yield take(accountUpdateChannel);
		yield put(action);
	}
}


/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchGetProvider),
		fork(watchConnect),
		fork(watchAccountUpdate),
		fork(watchAccountUpdateChannel),
	]);
}

export default sagas;
