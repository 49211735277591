import { Hardhat, Localhost, Mainnet } from '@usedapp/core';


export default {
	networks: [Mainnet, Localhost, Hardhat],
	readOnlyChainId: Mainnet.chainId,
	readOnlyUrls: {
		[Mainnet.chainId]: 'https://mainnet.infura.io/v3/de11ce29c75e43559be79977a0cfd5e7',
		[Localhost.chainId]: 'http://localhost:8545',
		[Hardhat.chainId]: 'http://localhost:8545',
	},
	multicallAddresses: {
		[Localhost.chainId]: '0x0FB54156B496b5a040b51A71817aED9e2927912E',
		[Hardhat.chainId]: '0x0FB54156B496b5a040b51A71817aED9e2927912E',
	},
}
