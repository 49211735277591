import { createSelector } from 'reselect';


const getBlockchain = (state, props) => {
	return state.blockchain;
};

export {
	getBlockchain,
}
