import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { DAppProvider } from '@usedapp/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';

import configureStore, { history } from '@@Redux/store';

import firebaseConfig from './firebase.config';
import infuraConfig from './infura.config';

import App from '@@Components/App';

import './css/app.scss';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);
// logEvent(analytics, 'notification_received');


ReactDOM.render(
	<Provider store={configureStore()}>
		<ConnectedRouter history={history}>
			{/*<DAppProvider config={infuraConfig}>*/}
				<App/>
			{/*</DAppProvider>*/}
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);
