import produce from 'immer';

import tokenTypes from '../tokens/types';
import types from './types';


const initialState = [];

const alerts = (state = initialState, action) => produce(state, draft => {
    let {} = action.payload || {};

    const { payload, type, error } = action;
    switch (type) {
        case types.ALERT_CLEAR.SUCCESS: {
            draft = draft.filter((item) => item.id !== payload.id);
            return draft;
        }
        case tokenTypes.TOKEN_MINT.FAILURE:
            if (error) {
                payload.id = Date.now();
                payload.style = 'danger';
                payload.heading = 'Error Minting';
                draft.push(payload);
            }
            return;

        default:
            return state;
    }
});

export default alerts;
