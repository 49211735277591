import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';

import Token from '@@Components/Token';
import ErrorFallback from '@@Components/ErrorFallback';

import Errors from '@@Components/Alerts';
import Footer from '@@Components/Footer';
import Home from '@@Components/Home';
import Loading from '@@Components/Loading';
import Header from '@@Components/Header';

import WalletConnection from '@@Components/WalletConnection';

import config from '@@Config';
import { colors } from '@@Constants';
import { operations as blockchainOperations, selectors as blockchainSelectors } from '@@Redux/blockchain';
import { getBlockchain } from '@@Redux/blockchain/selectors';

import background from '../img/10-2160.png';

const App = ({
 	getProvider,
	provider,
}) => {

	useEffect(() => {
		if (!provider) {
			getProvider();
		}
	}, [getProvider, provider]);

	return (
		<Fragment>

			<ErrorBoundary
				FallbackComponent={ErrorFallback}
				onReset={() => {}}
			>
				<Helmet
					titleTemplate={`${config.basePageTitle}: %s`}
					defaultTitle={config.basePageTitle}
				>
					<meta name="theme-color" content={colors.BLACK.hex} />
				</Helmet>

				<div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: -1}}>
					<img alt="" role="presentation" src={background} style={{objectFit: 'cover', height: '100vh' }} />
				</div>

				<Loading />

				<Header />

				{/*<Sounds />*/}

				<main className="pt-2">

					<Errors />

					<Switch>
						<Route exact path="/" render={(props) => (<Home {...props} />)}/>
						<Route path="/tokens/:id" render={(props) => (<Token {...props} />)}/>
					</Switch>
				</main>

				<Footer />

			</ErrorBoundary>
		</Fragment>
	);
}

const mapState = (state) => {
	const blockchain = getBlockchain(state);
	const { provider } = blockchain;
	return {
		provider,
	};
};

const mapDispatch = {
	connect: blockchainOperations.connect,
	getProvider: blockchainOperations.getProvider,
};

export default connect(
	mapState,
	mapDispatch,
)(withRouter(App));

