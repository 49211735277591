import actions from './actions';

const getProvider = (params) => (dispatch) => {
	console.info('[operation] getProvider()', params);
	return dispatch(actions.getProvider.request(params));
};

const connect = (params) => (dispatch) => {
	console.info('[operation] connect()', params);
	return dispatch(actions.connect.request(params));
};

export {
	getProvider,
	connect,
};
