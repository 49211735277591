const OWNER_TOKENS = {
	name: 'tokens/OWNER_TOKENS',
	REQUEST: 'tokens/OWNER_TOKENS_REQUEST',
	SUCCESS: 'tokens/OWNER_TOKENS_SUCCESS',
	FAILURE: 'tokens/OWNER_TOKENS_FAILURE',
};

const TOKEN_OWNER = {
	name: 'tokens/TOKEN_OWNER',
	REQUEST: 'tokens/TOKEN_OWNER_REQUEST',
	SUCCESS: 'tokens/TOKEN_OWNER_SUCCESS',
	FAILURE: 'tokens/TOKEN_OWNER_FAILURE',
};

const TOKEN_MINT = {
	name: 'tokens/TOKEN_MINT',
	REQUEST: 'tokens/TOKEN_MINT_REQUEST',
	SUCCESS: 'tokens/TOKEN_MINT_SUCCESS',
	FAILURE: 'tokens/TOKEN_MINT_FAILURE',
};

const TOKEN_RECYCLE = {
	name: 'tokens/TOKEN_RECYLCE',
	REQUEST: 'tokens/TOKEN_RECYCLE_REQUEST',
	SUCCESS: 'tokens/TOKEN_RECYCLE_SUCCESS',
	FAILURE: 'tokens/TOKEN_RECYCLE_FAILURE',
};

const TOKEN_MINTED = {
	name: 'tokens/TOKEN_MINTED',
	REQUEST: 'tokens/TOKEN_MINTED_REQUEST',
	SUCCESS: 'tokens/TOKEN_MINTED_SUCCESS',
	FAILURE: 'tokens/TOKEN_MINTED_FAILURE',
};

const TOTAL_SUPPLY = {
	name: 'tokens/TOTAL_SUPPLY',
	REQUEST: 'tokens/TOTAL_SUPPLY_REQUEST',
	SUCCESS: 'tokens/TOTAL_SUPPLY_SUCCESS',
	FAILURE: 'tokens/TOTAL_SUPPLY_FAILURE',
};


const MINT_STATE = {
	name: 'tokens/MINT_STATE',
	REQUEST: 'tokens/MINT_STATE_REQUEST',
	SUCCESS: 'tokens/MINT_STATE_SUCCESS',
	FAILURE: 'tokens/MINT_STATE_FAILURE',
};


export default {
	OWNER_TOKENS,
	TOKEN_OWNER,
	TOKEN_MINT,
	TOKEN_RECYCLE,
	TOKEN_MINTED,
	TOTAL_SUPPLY,
	MINT_STATE,
};
