import types from './types';


const clearAlert = {
    request: (params) => ({
        type: types.ALERT_CLEAR.REQUEST,
        payload: params
    }),
    success: (data) => ({
        type: types.ALERT_CLEAR.SUCCESS,
        payload: data,
    }),
    failure: (err) => ({
        type: types.ALERT_CLEAR.FAILURE,
    }),
};


export default {
    clearAlert
}
