import React, { useEffect, useState, Fragment } from 'react';
import {connect} from 'react-redux';

import { useEtherBalance, useEthers } from '@usedapp/core';
import {BigNumber, ethers} from 'ethers';
import Modal from 'react-modal';

import appConfig from '@@Config';

import NFT from './NFT';

import {getFunctions, httpsCallable} from 'firebase/functions';


import { operations as tokenOperations, selectors as tokenSelectors } from '@@Redux/tokens';
import { selectors as blockchainSelectors } from "@@Redux/blockchain";
import { mintStates } from "@@Constants";


const Mint = ({
	account,
	getMintState,
	mintToken,
	mintState,
	baseURI,
}) => {
	// const { activateBrowserWallet, account } = useEthers();
	// const userBalance = useEtherBalance(account);
	const [quantity, setQuantity] = useState(1);

	useEffect(() => {
		getMintState();
	}, [getMintState]);

	const handleMint = async (quantity) => {
		mintToken({ quantity });
	};

	const formattedBalance = Number(ethers.utils.formatEther(account.balance)).toFixed(4);
	const adequateFunds = formattedBalance >= appConfig.token.mintPrice;

	return (
		<Fragment>
			<div className="row justify-content-center">
				<div className="col-auto">
					{(mintState === mintStates.ACTIVE) && (
						<Fragment>
							<button
								className="btn btn-primary btn-round"
								disabled={quantity === 1}
								onClick={() => setQuantity(Math.max(1, quantity - 1))}
							>-</button>
						<button
							className="btn btn-lg btn-primary mx-3"
							onClick={() => handleMint(quantity)}
							disabled={!adequateFunds}
						>
							Mint {quantity}
						</button>
							<button
								className="btn btn-primary btn-round"
								disabled={quantity === appConfig.token.mintMax}
								onClick={() => setQuantity(Math.min(quantity + 1, appConfig.token.mintMax))}
							>+</button>

						</Fragment>
					)}

					{(mintState === mintStates.INACTIVE) && (
						<button
							className="btn btn-lg btn-primary-outline my-3"
							disabled={true}
						>
							Mint Inactive
						</button>
					)}

				</div>
			</div>

			{!adequateFunds && (
				<div className="alert alert-warning my-3">
					Sorry, you do not have enough funds available in the connected wallet to mint.
				</div>
			)}

			<div className="my-3">
				{appConfig.token.mintPrice !== 0 && (
					<strong>Price: {appConfig.token.mintPrice} {appConfig.token.currency.symbol} each &times; {quantity} = {appConfig.token.mintPrice * quantity}</strong>
				)}
			</div>

		</Fragment>
	);
};

const mapState = (state) => {
	const blockchain = blockchainSelectors.getBlockchain(state);
	const mintResult = tokenSelectors.getMintResult(state);
	const mintState = tokenSelectors.getMintState(state);
	const { account, baseURI } = blockchain;
	return {
		account,
		mintResult,
		mintState,
		baseURI,
	};
};

const mapDispatch = {
	mintToken: tokenOperations.mintToken,
	getMintState: tokenOperations.getMintState,
};

export default connect(
	mapState,
	mapDispatch,
)(Mint);
