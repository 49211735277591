import React, { useEffect, Fragment } from 'react';
import { useEtherBalance, useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import {operations as blockchainOperations, selectors as blockchainSelectors} from "@@Redux/blockchain";
import {operations as tokenOperations, selectors as tokenSelectors} from "@@Redux/tokens";
import {connect} from "react-redux";


const WalletConnection = ({
	account,
	connect,
	provider,
	chainId
}) => {

	// let hasWallet = typeof window.ethereum !== 'undefined';
	// const { activateBrowserWallet } = useEthers();

	useEffect(() => {}, []);

	const handleConnectWallet = () => {
		try {
			//activateBrowserWallet();
			connect()
		} catch (err) {
			console.error('Unable to connect wallet:', err);
		}
	}


	return (
		<div className="my-3">

			{/* no wallet installed */}
			{provider === null && (
				<div className="alert alert-warning mx-5 my-3">
					Please install a crypto wallet such as <a href="https://metamask.io" target="_blank" rel="noopener nofollow noreferrer">MetaMask</a> to continue.
				</div>
			)}

			{/* wallet not connected */}
			{(provider && !account) && (
				<div className="">
					<button className="btn btn-primary mb-2" onClick={handleConnectWallet}>Connect Wallet</button>
				</div>
			)}

			{/* wallet ready */}
			{account && (
				<div>
					Connected as:<br/>
					{account.address}<br/>
					{/*<p className="mb-3"><span className="badge bg-success">Connected</span></p>*/}
					{/*<p>Address: {account}</p>*/}
				</div>
			)}


		</div>

	);
}


const mapState = (state) => {
	const blockchain = blockchainSelectors.getBlockchain(state);
	const { account, provider, chainId } = blockchain;
	return {
		account,
		provider,
		chainId
	};
};

const mapDispatch = {
	connect: blockchainOperations.connect,
};

export default connect(
	mapState,
	mapDispatch,
)(WalletConnection);
